import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import VueTranslate from "vue-translate-plugin";
import VueNoty from "vuejs-noty";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import VueRouter from "vue-router";
import Vco from "v-click-outside";
import VueSweetalert2 from "vue-sweetalert2";
import VueClipboard from "vue-clipboard2";
import VueTheMask from "vue-the-mask";
import ViaCep from "vue-viacep";
import Paginate from "vuejs-paginate";
import App from "./App.vue";
import router from "./router/routes";
import store from "./state/store/index.js";

import "@/design/index.scss";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vco);
Vue.use(require("vue-moment"));
Vue.use(VueTranslate);
Vue.use(VueNoty, {
  theme: "sunset",
});
Vue.use(VueNoty);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(VueClipboard);
Vue.component("apexchart", VueApexCharts);
Vue.component("paginate", Paginate);
Vue.use(VueTheMask);
Vue.use(ViaCep);

import firebase from "firebase/app";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyBtAxI6avpC3JzyQkFkUF5Ds_5-AIE5Crw",
  authDomain: "m2n-artesanato.firebaseapp.com",
  databaseURL: "https://m2n-artesanato.firebaseio.com/",
  projectId: "m2n-artesanato",
  storageBucket: "m2n-artesanato.appspot.com",
  messagingSenderId: "73589572371",
  appId: "1:73589572371:web:87f0679fe10f05a64a50e6",
  measurementId: "G-JQDZ0FV8SC",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
